import "./styles/main.scss";

import bottomImage from "./assets/memoji.png";
import instagram from "./assets/instagram.png";
import email from "./assets/email.png";
import fiverr from "./assets/fiverr.png";

function App() {
  return (
    <div className="main">
      <div className="information">
        <div className="information__grid">
          <div className="information__grid__title">
            <h1>Oscar Sanz Sanchez</h1>
            <h2>Fullstack developer</h2>
          </div>
          <div className="information__grid__subtitle">
            <div className="icons">
              <a
                href="https://www.instagram.com/oscarsanz.dev/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="instagram" />
              </a>
              <a
                href="mailto:sanzsanchezoscar@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={email} alt="instagram" />
              </a>
              <a
                href="https://www.fiverr.com/oscarsanz"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fiverr} alt="fiverr" />
              </a>
            </div>
          </div>
          <div
            className="information__grid__subtitle--right"
            style={{
              gridRow: "2",
            }}
          >
            <h2
              style={{
                margin: 0,
              }}
            >
              Hello! Thanks for passing by.
            </h2>
            <p>
              I'm a fullstack developer, with DEVOPS knowledge and interested
              into UI/UX design too.
            </p>
            <p>
              Among other techonologies, I'm experienced with JS (and with
              supertype Typescript). You can find me working at frontend with
              React or Angular, in a backend being written in SpringBoot or
              NodeJS, and querying data stored at a Postgres SQL or Dynamo DB.
            </p>
            <p>
              And for reaching it to dev stage or production ready, I set up
              CI/CD pipelines into the Github repository, to deploy it in AWS
              (with the infrastucture ready thanks to the CloudFormation .yaml
              templates)
            </p>

            <p>
              However, sometimes the above stack is too much for a simple or
              personal application, so setting up a Docker image and publishing
              in DigitalOcean with some Kubernetes it's more appropiate.
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-image">
        <img src={bottomImage} alt="My Apple Memoji" />
      </div>
    </div>
  );
}

export default App;
